"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subdomains = void 0;
const Subdomains = {
  APP: 'app',
  APP_API: 'app-api'
};
exports.Subdomains = Subdomains;