"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAcceptanceTest = void 0;

const isAcceptanceTest = () => {
  return !!window.navigator.webdriver;
};

exports.isAcceptanceTest = isAcceptanceTest;